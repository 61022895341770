.scl-preloader__wrapper {
    background-color: rgba(0, 0, 0, 1);
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
}

.scl-preloader {
    display: block;
    width: 80px;
    height: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.8);
    z-index: 999;

    img {
        max-width: 100%;
        margin-top: 100%;
        padding-top: 10px;
        opacity: 1;
        animation: fadeinpreloaderimage 1s;
    }
}

@keyframes fadeinpreloaderimage {
    from {
        opacity: 0;
        transform: scale(0);
        /* stylelint-disable-next-line rule-empty-line-before */
    } to {
        opacity: 1;
        transform: scale(1);
    }
}

.scl-preloader div {
    animation: lds-roller 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
    transform-origin: 40px 40px;
}

.scl-preloader div::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.scl-preloader div:nth-child(1) {
    animation-delay: -.036s;
}

.scl-preloader div:nth-child(1)::after {
    top: 63px;
    left: 63px;
}

.scl-preloader div:nth-child(2) {
    animation-delay: -.072s;
}

.scl-preloader div:nth-child(2)::after {
    top: 68px;
    left: 56px;
}

.scl-preloader div:nth-child(3) {
    animation-delay: -.108s;
}

.scl-preloader div:nth-child(3)::after {
    top: 71px;
    left: 48px;
}

.scl-preloader div:nth-child(4) {
    animation-delay: -.144s;
}

.scl-preloader div:nth-child(4)::after {
    top: 72px;
    left: 40px;
}

.scl-preloader div:nth-child(5) {
    animation-delay: -.18s;
}

.scl-preloader div:nth-child(5)::after {
    top: 71px;
    left: 32px;
}

.scl-preloader div:nth-child(6) {
    animation-delay: -.216s;
}

.scl-preloader div:nth-child(6)::after {
    top: 68px;
    left: 24px;
}

.scl-preloader div:nth-child(7) {
    animation-delay: -.252s;
}

.scl-preloader div:nth-child(7)::after {
    top: 63px;
    left: 17px;
}

.scl-preloader div:nth-child(8) {
    animation-delay: -.288s;
}

.scl-preloader div:nth-child(8)::after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
